import JoditEditor from 'jodit-react'
import React, {useEffect, useMemo, useRef, useState} from 'react'
type Props = {
  formik: any
  name: string
}
const CustomJoditEditor: React.FC<Props> = ({formik, name}) => {
  const editor = useRef<any>(null)
  const [content, setContent] = useState<any>(formik.values[name])
  // useEffect(() => {
  //   setContent(formik.values[name])
  // }, [formik.values[name]])
  const config = {
    buttons: 'ul,ol,fullsize,paste,brush',
 

    // buttons: ',font,fontsize,,paragraph,|,align,undo,redo,|,hr,symbol,fullsize,image,video,table,link,|,cut,copy,paste,pasteText,pasteWord,|,selectall,selecthtml,|,print,about',

    toolbarAdaptive: false,
    toolbarSticky: false,
    showCharsCounter: false,
    showWordsCounter: false,
    buttonsXS: {
      uppercase: {
        command: 'toggleUpper',
        tags: ['span', 'strong', 'em', 'i', 'b']
      }
    },
    colorPicker: {
      defaultColors: ['#023e8a', '#ff0000'] 
    }
    
  }
  useEffect(() => {
    formik.setFieldValue(name, content)
  }, [content])
  return (
    <JoditEditor
      ref={editor}
      value={content}
      config={config}
      //   tabIndex={"1"}
      onBlur={(newContent) => {
        setContent(newContent)
        formik.setFieldValue(name, newContent)
      }}
      //   onChange={newContent => setContent(...newContent)}
    />
  )
}

export default CustomJoditEditor
