import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import { PartnersListWrapper } from './partners-list/PartnersList'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Liste des partenaires',
    path: '/partners/liste/all',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PartnersPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='all'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Tous les partenaires</PageTitle>
              <PartnersListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/partners/liste/all' />} />
    </Routes>
  )
}

export default PartnersPage
