import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponse} from './core/QueryResponseProvider'
import {ListHeader} from './components/header/ListHeader'
import {KTCard} from '../../../../../_metronic/helpers'
import {AboutTable} from './table/AboutTable'
import AboutContent from './AboutContent'
import { ListLoading } from './components/loading/ListLoading'

const AboutList = () => {
  const {itemIdForUpdate} = useListView()
  const {isLoading} = useQueryResponse()

  return (
    <>
      <KTCard>
        {!isLoading ? <AboutContent />:<ListLoading/>}

        {/* <ListHeader />
        <AboutTable /> */}
      </KTCard>
      {/* {itemIdForUpdate !== undefined && <EditModal />} */}
    </>
  )
}

const AboutListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AboutList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {AboutListWrapper}
