import {useFormik} from 'formik'
import React, {useContext, useEffect, useMemo, useState} from 'react'
import * as Yup from 'yup'
import {ObjectToArrayArrayFormData, ObjectToArrayFormData} from '../../../../../_metronic/helpers/function/ObjectToFormData'
import {isNotEmpty} from '../../../../../_metronic/helpers'
import {AppContext} from '../../../../../AppContext'
import {updateAbout} from './core/_requests'
import Input from '../../../../../_metronic/layout/forms/Inputs/Input'
import Textarea from '../../../../../_metronic/layout/forms/Inputs/Textarea'
import {ListLoading} from './components/loading/ListLoading'
import {useQueryResponseData} from './core/QueryResponseProvider'

function AboutContent() {
  const [isUserLoading, setIsUserLoading] = useState<boolean>(false)
  const {errorToast, successToast} = useContext(AppContext)
  const users = useQueryResponseData()
  const data: any = useMemo(() => users[0], [users])

  const [userForEdit, setUserForEdit] = useState<any>({
    id: data.id ? data.id : '',
    content: data.content ? data.content : '',
    content_en: data.content_en ? data.content_en : '',
    paragraph1: data.paragraph1 ? data.paragraph1 : '',
    paragraph1_en: data.paragraph1_en ? data.paragraph1_en : '',
    paragraph2: data.paragraph2 ? data.paragraph2 : '',
    paragraph2_en: data.paragraph2_en ? data.paragraph2_en : '',
    paragraph3: data.paragraph3 ? data.paragraph3 : '',
    paragraph3_en: data.paragraph3_en ? data.paragraph3_en : '',
    paragraph4: data.paragraph4 ? data.paragraph4 : '',
    paragraph4_en: data.paragraph4_en ? data.paragraph4_en : '',
    textCompany: data.textCompany ? data.textCompany : '',
    textCompany_en: data.textCompany_en ? data.textCompany_en : '',
    textTeam: data.textTeam ? data.textTeam : '',
    textTeam_en: data.textTeam_en ? data.textTeam_en : '',
    textQuality: data.textQuality ? data.textQuality : '',
    textQuality_en: data.textQuality_en ? data.textQuality_en : '',
    textProject: data.textProject ? data.textProject : '',
    textProject_en: data.textProject_en ? data.textProject_en : '',
    textClient: data.textClient ? data.textClient : '',
    textClient_en: data.textClient_en ? data.textClient_en : '',
  })

  const editUserSchema = Yup.object().shape({
    content: Yup.string().required('Le contenu est requis'),
  })
  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const dataToSend = await ObjectToArrayArrayFormData(values)
        console.log(dataToSend);
        

        await updateAbout(dataToSend, values.id)
        await successToast('A propos mise a jour avec succès')
      } catch (ex: any) {
        if (ex.response && ex.response.data && ex.response.data.error) {
          const detailedError = ex.response.data.error
          console.error('Detailed Error:', detailedError)
          errorToast(detailedError)
        } else {
          console.error('Generic Error:', ex.message)
          errorToast('An error occurred. Please try again.')
        }

        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })
  return (
    <div className='container'>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='row my-7 '>
          <div className='col-sm-12 col-md-6 col-lg-6 '>
            <Textarea
              name='content'
              formik={formik}
              label='Description'
              isRequired={true}
              isUserLoading={isUserLoading}
            />{' '}
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 '>
            <Textarea
              name='content_en'
              formik={formik}
              label='Description en'
              isRequired={true}
              isUserLoading={isUserLoading}
            />{' '}
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 '></div>
        </div>
        <div className='row my-7 '>
          <div className='col-sm-12 col-md-6 col-lg-6 '>
            <Textarea
              name='paragraph1'
              formik={formik}
              label='Premier paragraphe'
              isRequired={true}
              isUserLoading={isUserLoading}
            />{' '}
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 '>
            <Textarea
              name='paragraph1_en'
              formik={formik}
              label='Premier paragraphe en'
              isRequired={true}
              isUserLoading={isUserLoading}
            />{' '}
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 '></div>
        </div>
        <div className='row my-7 border-top'></div>
        <div className='row my-7 '>
          <div className='col-sm-12 col-md-6 col-lg-6 '>
            <Textarea
              name='paragraph2'
              formik={formik}
              label='Deuxième paragraphe'
              isRequired={true}
              isUserLoading={isUserLoading}
            />{' '}
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 '>
            <Textarea
              name='paragraph2_en'
              formik={formik}
              label='Deuxième paragraphe en'
              isRequired={true}
              isUserLoading={isUserLoading}
            />{' '}
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 '></div>
        </div>
        <div className='row my-7 '>
          <div className='col-sm-12 col-md-6 col-lg-6 '>
            <Textarea
              name='paragraph3'
              formik={formik}
              label='Troisième paragraphe'
              isRequired={true}
              isUserLoading={isUserLoading}
            />{' '}
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 '>
            <Textarea
              name='paragraph3_en'
              formik={formik}
              label='Troisième paragraphe en'
              isRequired={true}
              isUserLoading={isUserLoading}
            />{' '}
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 '></div>
        </div>
        <div className='row my-7 '>
          <div className='col-sm-12 col-md-6 col-lg-6 '>
            <Textarea
              name='paragraph4'
              formik={formik}
              label='Quatrième paragraphe'
              isRequired={true}
              isUserLoading={isUserLoading}
            />{' '}
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 '>
            <Textarea
              name='paragraph4_en'
              formik={formik}
              label='Quatrième paragraphe en'
              isRequired={true}
              isUserLoading={isUserLoading}
            />{' '}
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 '></div>
        </div>
        <div className='row my-7 border'></div>

        <div className='row my-7 '>
          <div className='col-sm-12 col-md-6 col-lg-6 '>
            <Textarea
              name='textClient'
              formik={formik}
              label='Clients satisfaits'
              isRequired={true}
              isUserLoading={isUserLoading}
            />{' '}
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 '>
            <Textarea
              name='textClient_en'
              formik={formik}
              label='Clients satisfaits en'
              isRequired={true}
              isUserLoading={isUserLoading}
            />{' '}
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 '></div>
        </div>
        <div className='row my-7 '>
          <div className='col-sm-12 col-md-6 col-lg-6 '>
            <Textarea
              name='textProject'
              formik={formik}
              label='Projet complet'
              isRequired={true}
              isUserLoading={isUserLoading}
            />{' '}
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 '>
            <Textarea
              name='textProject_en'
              formik={formik}
              label='Projet complet en'
              isRequired={true}
              isUserLoading={isUserLoading}
            />{' '}
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 '></div>
        </div>
        <div className='row my-7 '>
          <div className='col-sm-12 col-md-6 col-lg-6 '>
            <Textarea
              name='textQuality'
              formik={formik}
              label='Qualité service'
              isRequired={true}
              isUserLoading={isUserLoading}
            />{' '}
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 '>
            <Textarea
              name='textQuality_en'
              formik={formik}
              label='Qualité service en'
              isRequired={true}
              isUserLoading={isUserLoading}
            />{' '}
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 '></div>
        </div>
        <div className='row my-7 border'></div>
        <div className='row my-7 '>
          <div className='col-sm-12 col-md-6 col-lg-6 '>
            <Textarea
              name='textTeam'
              formik={formik}
              label='Qualité service'
              isRequired={true}
              isUserLoading={isUserLoading}
            />{' '}
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 '>
            <Textarea
              name='textTeam_en'
              formik={formik}
              label='Qualité service en'
              isRequired={true}
              isUserLoading={isUserLoading}
            />{' '}
          </div>
        </div>
        <div className='row my-7 '>
          <div className='col-sm-12 col-md-6 col-lg-6 '>
            <Textarea
              name='textCompany'
              formik={formik}
              label='Compagnie'
              isRequired={true}
              isUserLoading={isUserLoading}
            />{' '}
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 '>
            <Textarea
              name='textCompany_en'
              formik={formik}
              label='Compagnie en'
              isRequired={true}
              isUserLoading={isUserLoading}
            />{' '}
          </div>
        </div>

        <div className='text-center pt-15'>
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Valider</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <ListLoading />}
    </div>
  )
}

export default AboutContent
