import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {IoIosMailOpen} from 'react-icons/io'
import {TbCategory2} from 'react-icons/tb'
import {FaHandsHelping} from 'react-icons/fa'

import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  StatisticsWidget5,
  ListsWidget1,
} from '../../../_metronic/partials/widgets'
import {useEffect, useState} from 'react'
import {useAuth} from '../../modules/auth'
import {getUserByToken} from '../../modules/auth/core/_requests'
import axios from 'axios'
const totalProject = 10
const totalListEmails = 10
const totalDesPartners = 10
type Props = {
  data?: any
}
const DashboardPage: React.FC<Props> = ({data}) => (
  <>
    <p className='text-dark  fs-4 mb-5  text-left w-75 pb-5'>
      Bienvenue ! Votre tableau de bord est prêt.
      <br /> Avec notre interface élégante, gérez votre site avec simplicité. C'est le meilleur de
      la gestion en ligne. Prêt à vous plonger ?
    </p>
    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-4'>
        <StatisticsWidget5
          className='card-xl-stretch mb-xl-8'
          reactIcon={<IoIosMailOpen />}
          color='body-white'
          iconColor='primary'
          title='Total des demandes'
          description={data?.listEmailCount && data?.listEmailCount + ''}
          titleColor='gray-900'
          descriptionColor='gray-400'
        />
      </div>

      <div className='col-xl-4'>
        <StatisticsWidget5
          className='card-xl-stretch mb-xl-8'
          reactIcon={<TbCategory2 />}
          color='primary'
          iconColor='white'
          title='Total des projets'
          description={data?.projectCount && data?.projectCount + ''}
          titleColor='white'
          descriptionColor='white'
        />
      </div>

      <div className='col-xl-4'>
        <StatisticsWidget5
          className='card-xl-stretch mb-5 mb-xl-8'
          reactIcon={<FaHandsHelping />}
          color='dark'
          iconColor='gray-100'
          title='Total des partenaires'
          description={data?.partnersCount && data?.partnersCount + ''}
          titleColor='gray-100'
          descriptionColor='gray-100'
        />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    {/* <div className='row g-5 g-xl-8'>

      <div className='col-xl-4'>
        <ListsWidget1 className='card-xl-stretch mb-xl-8' />
      </div>

      <div className='col-xl-8'>
        <TablesWidget5 className='card-xl-stretch mb-5 mb-xl-8' />
      </div>
 
    </div>
  
    <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-4'>
        <MixedWidget2
          className='card-xl-stretch mb-xl-8'
          chartColor='danger'
          chartHeight='200px'
          strokeColor='#cb1e46'
        />
      </div>
      <div className='col-xxl-4'>
        <ListsWidget5 className='card-xxl-stretch' />
      </div>
      <div className='col-xxl-4'>
        <MixedWidget10
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='150px'
        />
        <MixedWidget11
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='175px'
        />
      </div>
    </div>
   
    <div className='row gy-5 gx-xl-8'>
      <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div>
      <div className='col-xl-8'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    
    <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
      </div>
    </div> */}
    {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
  </>
)

const DashboardWrapper = () => {
  const {auth, logout, setCurrentUser} = useAuth()
  const [total, setTotal] = useState([])
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL2}/api/dashboard`).then((response: any) => {
      setTotal(response.data)
    })
  }, [])

  const CheckIsAuth = async () => {
    try {
      const token = localStorage.getItem('token')

      if (!token) {
        const {data} = await getUserByToken(auth?.access_token)

        if (data) {
          setCurrentUser(data)
        } else {
          setCurrentUser(undefined)
          logout()
        }
      }
    } catch (error) {
      setCurrentUser(undefined)
      logout()
    }
  }
  useEffect(() => {
    CheckIsAuth()
  }, [])
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage data={total} />
    </>
  )
}

export {DashboardWrapper}
