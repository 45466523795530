import {FC, useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {initialUser, User} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {ListLoading} from '../components/loading/ListLoading'
import {useQueryResponse} from '../core/QueryResponseProvider'
import FileInput from '../../../../../../_metronic/layout/forms/files/FileInput'
import Input from '../../../../../../_metronic/layout/forms/Inputs/Input'
import Textarea from '../../../../../../_metronic/layout/forms/Inputs/Textarea'
import {
  ObjectToArrayArrayFormData,
  ObjectToArrayFormData,
  ObjectToFormData,
} from '../../../../../../_metronic/helpers/function/ObjectToFormData'
import {ObjectToUrlEncoded} from '../../../../../../_metronic/helpers/function/ObjectToUrlEncoded'
import {AppContext} from '../../../../../../AppContext'
import DropZone from '../../../../../../_metronic/layout/forms/files/DropZone'
import SearshSelect from '../../../../../../_metronic/layout/forms/Inputs/SearshSelect'
import {createService, updateService} from '../core/_requests'
import CustomJoditEditor from '../../../../../../_metronic/layout/forms/Editor/JoditEditor'

type Props = {
  isUserLoading: boolean
  user: User
}
const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')

const editUserSchema = Yup.object().shape({
  // description: Yup.string(),
  // description_en: Yup.string(),
  // name_en: Yup.string(),
  // budget: Yup.number().required('Le budget est requis'),
  // city: Yup.string().required('La ville est requise'),
  // city_en: Yup.string().required('La ville est requise'),
  // name: Yup.string()
  //   .min(3, 'Au moins 3 caractères')
  //   .max(50, 'Maximum 50 caractères')
  //   .required('Le nom est requis'),
  // image: Yup.mixed().required('Le fichier est requis'),
})

const EditModalForm: FC<Props> = ({user, isUserLoading}) => {
  const {errorToast, successToast} = useContext(AppContext)
  const [categories, setCategories] = useState<any>([])
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [userForEdit] = useState<User>({
    ...user,
    image_1:
      user.rowservices &&
      user.rowservices.length > 0 &&
      user.rowservices[0] &&
      user.rowservices[0].image_url
        ? user.rowservices[0].image_url
        : blankImg,
    image_2:
      user.rowservices &&
      user.rowservices.length > 0 &&
      user.rowservices[1] &&
      user.rowservices[1].image_url
        ? user.rowservices[1].image_url
        : blankImg,
    image_3:
      user.rowservices &&
      user.rowservices.length > 0 &&
      user.rowservices[2] &&
      user.rowservices[2].image_url
        ? user.rowservices[2].image_url
        : blankImg,
    content_1:
      user.rowservices &&
      user.rowservices.length > 0 &&
      user.rowservices[0] &&
      user.rowservices[0].content
        ? user.rowservices[0].content
        : '',
    content_2:
      user.rowservices &&
      user.rowservices.length > 0 &&
      user.rowservices[1] &&
      user.rowservices[1].content
        ? user.rowservices[1].content
        : '',
    content_3:
      user.rowservices &&
      user.rowservices.length > 0 &&
      user.rowservices[2] &&
      user.rowservices[2].content
        ? user.rowservices[2].content
        : '',
    content_en_1:
      user.rowservices &&
      user.rowservices.length > 0 &&
      user.rowservices[0] &&
      user.rowservices[0].content_en
        ? user.rowservices[0].content_en
        : '',
    content_en_2:
      user.rowservices &&
      user.rowservices.length > 0 &&
      user.rowservices[1] &&
      user.rowservices[1].content_en
        ? user.rowservices[1].content_en
        : '',
    content_en_3:
      user.rowservices &&
      user.rowservices.length > 0 &&
      user.rowservices[2] &&
      user.rowservices[2].content_en
        ? user.rowservices[2].content_en
        : '',

    id_1:
      user.rowservices &&
      user.rowservices.length > 0 &&
      user.rowservices[0] &&
      user.rowservices[0].id
        ? user.rowservices[0].id
        : 'undefined',

    id_2:
      user.rowservices &&
      user.rowservices.length > 0 &&
      user.rowservices[1] &&
      user.rowservices[1].id
        ? user.rowservices[1].id
        : 'undefined',
    id_3:
      user.rowservices &&
      user.rowservices.length > 0 &&
      user.rowservices[2] &&
      user.rowservices[2].id
        ? user.rowservices[2].id
        : 'undefined',

    // avatar: user.avatar || initialUser.avatar,
    // role: user.role || initialUser.role,
    // position: user.position || initialUser.position,
  })

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      const service = [
        {
          id: values.id_1,
          content: values.content_1,
          content_en: values.content_en_1,
          image: values.image_1,
        },
        {
          id: values.id_2,
          content: values.content_2,
          content_en: values.content_en_2,
          image: values.image_2,
        },
        {
          id: values.id_3,
          content: values.content_3,
          content_en: values.content_en_3,
          image: values.image_3,
        },
      ]
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          const dataToSend = await ObjectToArrayArrayFormData({service, ...values})
          console.log(dataToSend)

          await updateService(dataToSend, values.id)
          await successToast('Catégorie mise a jour avec succès')
          await cancel(true)
        } else {
          const dataToSend = await ObjectToArrayFormData(values)
          await createService(dataToSend)
          await successToast('Catégorie crée avec succès')
          await cancel(true)
        }
      } catch (ex: any) {
        if (ex.response && ex.response.data && ex.response.data.error) {
          const detailedError = ex.response.data.error
          console.error('Detailed Error:', detailedError)
          errorToast(detailedError)
        } else {
          console.error('Generic Error:', ex.message)
          errorToast('An error occurred. Please try again.')
        }

        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  // const getCategorie = async () => {
  //   const {data} = await getCategories('')
  //   if (!data) {
  //     setCategories([])
  //     return
  //   }
  //   const modifiedData = await data.map((item) => ({
  //     label: item.name,
  //     value: item.id,
  //   }))

  //   setCategories(modifiedData)
  // }
  // useEffect(() => {
  //   getCategorie()
  // }, [])
  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='row my-7 '>
          <Input
            type='name'
            name='name'
            formik={formik}
            label='Nom'
            isUserLoading={isUserLoading}
            isDisabled={true}
          />{' '}
        </div>
        <div className='row my-7 '>
          <div className='col-sm-12 col-md-6 col-lg-6 '>
            <Textarea
              name='description'
              formik={formik}
              label='Description'
              isUserLoading={isUserLoading}
            />{' '}
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 '>
            <Textarea
              name='description_en'
              formik={formik}
              label='Description Anglais'
              isUserLoading={isUserLoading}
            />{' '}
          </div>
        </div>
        {/* begin::Scroll */}
        <div className='row'>
          <div
            className='d-flex flex-column scroll-y me-n7 pe-7'
            id='kt_modal_add_user_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_user_header'
            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
            data-kt-scroll-offset='300px'
          >
            {/* begin::Input group */}
            <div className='row my-7 '>
              <div className='col-sm-12 col-md-6 col-lg-6 '>
                <FileInput formik={formik} name='image_1' label='Image' isRequired={true} />
              </div>
            </div>
          </div>
          <div className='row '>
            <div className='col-sm-12 col-md-6 col-lg-6 '>
              <CustomJoditEditor name='content_1' formik={formik} />

              {/* <Textarea
                name='content_1'
                formik={formik}
                label='Content '
                isUserLoading={isUserLoading}
              /> */}
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6 '>
              <CustomJoditEditor name='content_en_1' formik={formik} />
              {/* <Textarea
                name='content_en_1'
                formik={formik}
                label='Content anglais'
                isUserLoading={isUserLoading}
              /> */}
            </div>
          </div>
        </div>
        <div className='row'>
          <div
            className='d-flex flex-column scroll-y me-n7 pe-7'
            id='kt_modal_add_user_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_user_header'
            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
            data-kt-scroll-offset='300px'
          >
            {/* begin::Input group */}
            <div className='row my-7 '>
              <div className='col-sm-12 col-md-6 col-lg-6 '>
                <FileInput formik={formik} name='image_2' label='Image' isRequired={true} />
              </div>
            </div>
          </div>
          <div className='row '>
            <div className='col-sm-12 col-md-6 col-lg-6 '>
              {/* <Textarea
                name='content_2'
                formik={formik}
                label='Content '
                isUserLoading={isUserLoading}
              /> */}
              <CustomJoditEditor name='content_2' formik={formik} />
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6 '>
              {/* <Textarea
                name='content_en_2'
                formik={formik}
                label='Content anglais'
                isUserLoading={isUserLoading}
              /> */}
              <CustomJoditEditor name='content_en_2' formik={formik} />
            </div>
          </div>
        </div>
        <div className='row'>
          <div
            className='d-flex flex-column scroll-y me-n7 pe-7'
            id='kt_modal_add_user_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_user_header'
            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
            data-kt-scroll-offset='300px'
          >
            {/* begin::Input group */}
            <div className='row my-7 '>
              <div className='col-sm-12 col-md-6 col-lg-6 '>
                <FileInput formik={formik} name='image_3' label='Image' isRequired={true} />
              </div>
            </div>
          </div>
          <div className='row '>
            <div className='col-sm-12 col-md-6 col-lg-6 '>
              {/* <Textarea
                name='content_3'
                formik={formik}
                label='Content '
                isUserLoading={isUserLoading}
              /> */}
              <CustomJoditEditor name='content_3' formik={formik} />
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6 '>
              {/* <Textarea
                name='content_en_3'
                formik={formik}
                label='Content anglais'
                isUserLoading={isUserLoading}
              /> */}
              <CustomJoditEditor name='content_en_3' formik={formik} />
            </div>
          </div>
        </div>
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Annuler
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Valider</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <ListLoading />}
    </>
  )
}

export {EditModalForm}
